<template>
  <div class="cities-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">City Content</h4>
          <div class="main-contents">
            <fd-select
              class="mt-2"
              :label="'Country'"
              v-model="city.countryId"
              :required="true"
              :options="countries"
              @change="countryChange"
            >
            </fd-select>
            <fd-select
              class="mt-2"
              :label="'State'"
              v-model="city.stateId"
              :required="true"
              :options="states.data"
              :disabled="city.countryId == ''"
            >
            </fd-select>
            <fd-input
              class="mt-2"
              type="text"
              :label="`City Name`"
              v-model="city.name"
              :required="true"
            />
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { CityModel } from "@/models";

export default {
  name: "cities-edit",
  data() {
    return {
      countries: [],
      states: {
        data: []
      },
      city: {
        countryId: "",
        stateId: "",
        name: ""
      },
      cloneCity: {
        countryId: "",
        stateId: "",
        name: ""
      }
    };
  },
  async mounted() {
    this.$store.commit("setIsLoading", true, { root: true });
    await this.loadCity();
    await Promise.all([this.loadCountries(), this.loadStates()]);
    this.$store.commit("setIsLoading", false, { root: true });
  },
  watch: {
    "city.countryId": {
      async handler(newVal, oldVal) {
        if (newVal == this.cloneCity.countryId) {
          await this.loadStates();
          this.city.stateId = this.cloneCity.stateId;
        } else {
          this.loadStates();
          this.city.stateId = "";
        }
      }
    }
  },
  methods: {
    countryChange() {
      this.loadStates();
    },
    async loadCountries() {
      try {
        let data = await this.$store.dispatch(
          "manageCountries/getAllCountries",
          {
            page: 1,
            limit: 1000000
          }
        );

        this.countries = this._.cloneDeep(data.data);
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async loadStates() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await this.$store.dispatch("manageStates/getAllStates", {
          page: 1,
          limit: 1000000,
          "country:id": this.city.countryId
        });

        this.states.data = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        throw error;
      }
    },
    async loadCity() {
      const id = this.$route.params.id;
      try {
        const request = await this.$store.dispatch("manageCities/getCity", id);

        this.city = CityModel.getCityResponse(this._.cloneDeep(request));
        this.cloneCity = CityModel.getCityResponse(this._.cloneDeep(request));

        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    },
    async submitForm() {
      const id = this.$route.params.id;
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "manageCities/updateCities",
          {
            id: id,
            data: CityModel.postCityPayload(this.city)
          }
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "City updated successfully."
        });
        this.$router.push({ path: "/manage-cities" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: " An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.cities-edit {
  form {
    @extend %formDesign;
  }
}
</style>